var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('payments.title'),"paths":[{title:_vm.$t('settings.title'),link:'settings'}]}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData}})],1),_c('section',{staticClass:"\n        wameed-dashboard-page-content_body\n        d-flex\n        flex-column\n        justify-content-between\n        overflow-hidden\n      "},[_c('div',{staticClass:"d-flex flex-wrap flex-1"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[
                { name: 'online' },
                { name: 'name' },
                { name: 'account_number' },
                { name: 'status' },
                { name: 'action' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
              var data = ref.data;
return [_c('name-tile',{attrs:{"name":data.item.name,"image":data.item.image,"data-id":data.item.id}})]}},{key:"account_number",fn:function(ref){
              var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.account_number}})]}},{key:"online",fn:function(ref){
              var data = ref.data;
return [(data.item.online==1)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('payments.online'))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('payments.offline'))+" ")])]}},{key:"status",fn:function(ref){
              var data = ref.data;
return [_c('wameed-switch',{attrs:{"checked":data.item.status ==1},on:{"onChange":function (e){e.preventDefault();  _vm.onChange(data.item)}}})]}},{key:"action",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"table-option order-handler"},[_c('div',{staticClass:"order"},[_c('o-order-icon')],1)]),(data.item.type==null)?_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"permission",rawName:"v-permission",value:([_vm.$route.meta.pagePermissions.edit]),expression:"[$route.meta.pagePermissions.edit]"}],staticClass:"permission-edit-item",on:{"click":function (){ return _vm.openUpdateModal(data.item); }}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('edit-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('btn.edit'))+" ")],1)])],1):_vm._e()]}}],null,false,3592376299)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)]),_c('payment-modal',{attrs:{"visible":_vm.showModal,"modal-data":_vm.modalData},on:{"close":function($event){_vm.showModal = false},"onComplete":function (){ return _vm.loadData(); }}}),_c('warning-modal',{attrs:{"variant":"main","iconName":"o-toggle-icon","visible":_vm.showToggleModal,"title":_vm.toggleModal.title,"subTitle":_vm.toggleModal.subTitle,"btnTitle":_vm.toggleModal.btn},on:{"close":_vm.closeToggleModal,"submitAction":_vm.toggleAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }